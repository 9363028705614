import { default as NextLink } from "next/link"
import NextImage from "next/legacy/image"
import { usePathData } from "@/lib/contexts/appContext"
import Block from "@/components/wrappers/block"
import styles from "./image.module.css"
import classNames from "classnames"

function Image({ block, settings }) {
  const { template } = usePathData()
  const { attrs, html } = block
  const { image, sizeSlug } = attrs

  // FIXME how does this happen?
  if (html) {
    return (
      <Block
        block={block}
        className={classNames(
          styles.image,
          attrs.classList,
          settings?.innerAlign
        )}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Block>
    )
  }

  if (!image) {
    return null
  }

  const Link = ({ children }) => {
    if (!attrs.link) {
      return <>{children}</>
    }

    return (
      <NextLink
        href={attrs.link.url}
        prefetch={false}
        rel={attrs.link.rel}
        target={attrs.link.target}>
        {children}
      </NextLink>
    )
  }

  const Element = ({ image }) => {
    let classList = attrs.classList

    if (settings?.sizeFull === false) {
      classList = attrs.classList.filter(function (item) {
        return item !== "size-full"
      })
    }

    const forceSquare = attrs?.forceSquare ? "force-square" : ""

    const imageAttrs = {
      src: image.url,
      alt: image.alt,
      sizes: image.sizes,
      layout: forceSquare ? "fill" : "responsive",
      objectFit: "cover"
    }

    if (!forceSquare) {
      imageAttrs.width = image.width
      imageAttrs.height = image.height
    }

    if (settings?.priority) {
      imageAttrs.priority = true
    }

    return (
      <Link>
        <figure className={classNames(styles.image, classList, forceSquare)}>
          {image.url && <NextImage {...imageAttrs} />}
          {image.caption && <figcaption>{image.caption}</figcaption>}
        </figure>
      </Link>
    )
  }

  const widths = {
    full: "bleed",
    large: "large",
    medium: "column",
    thumbnail: "column"
  }

  const noStyling = attrs?.classList.includes("featured-image")

  let gap = template === "article" ? "small" : "normal"
  let width = widths[sizeSlug ?? "column"]

  width = settings?.width ?? width
  gap = settings?.gap ?? attrs?.blockGap

  return (
    <Block
      block={block}
      noStyling={noStyling}
      className={classNames(styles.wrapper, settings?.innerAlign)}
      width={width}
      gap={gap}>
      <Element image={image} />
    </Block>
  )
}

export default Image
